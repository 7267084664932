.connect-account-overlay {
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  max-width: 100%;
  height: 100vh;
  margin: 0 auto;
  background: #f6f7f9;

  @media screen and (max-width: 280px) {
    max-width: 280px;
  }
  .blurred {
    filter: blur(1px);
    pointer-events: none;
  }
  .connect-account-main {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
    padding-top: 169px;
    margin: 0 auto;
    @media screen and (max-width: "768px") {
      padding: 20px;
    }
    @media screen and (max-width: "280px") {
      font-size: 12px;
    }

    .flick-link-box {
      width: 24%;
      max-width: 347px;
      border-radius: 10px;
      background: #fff;
      @media screen and (max-width: 768px) {
        width: 100%;
        max-width: 100%;
      }

      .flick-link-title {
        width: 100%;
        display: flex;
        gap: 15px;
        padding: 18px 0px 18px 0px;
        border-bottom: 1px solid rgba(231, 234, 238, 0.4);
        .flick-link {
          display: flex;
          width: 100%;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 4px;
          .link-demo {
            color: var(--gray-900, #101828);
            font-size: 16px;
            font-weight: 500;
            line-height: normal;
            letter-spacing: -0.32px;
          }
          .explore-flick {
            color: var(--Flick-Subtext, #8c8f97);
            text-align: center;
            font-size: 12px;
            font-weight: 450;
            line-height: normal;
            letter-spacing: -0.24px;
          }
        }
      }

      .flick-link-form {
        width: 100%;
        padding: 0px 21px 33px 21px;
        margin-top: 14px;
        .flick-link-inner {
          display: flex;
          flex-direction: column;
          gap: 16px;
          margin-bottom: 24px;

          .input_container {
            position: relative;
            padding-top: 10px;
            width: 100%;
            .input-group {
              border-radius: 8px;
              border: 1px solid var(--Flick-Stroke, #eaecf0);
              background: #fff;
              display: flex;
              align-items: center;
              background: #fff;
              padding: 16px 10px 17px 10px;
              .input-name {
                color: var(--flick-secondary-5, #151f32);
                font-size: 12px;
                font-weight: 300;
                line-height: normal;
                border: none;
                width: 90%;
                &:focus {
                  outline: none;
                }
              }
              .input-name,
              textarea {
                caret-color: var(--flick-primary-5, #2ebdb6);
              }
            }
            .input-label {
              position: absolute;
              top: 0;
              left: 7px;
              color: var(--Flick-Subtext, #8c8f97);
              font-size: 12px;
              font-weight: 450;
              line-height: 20px;
              background: #fff;
              // padding: 4px;
            }

            .input-group.clicked {
              border: 1px solid var(--flick-primary-5, #2ebdb6);
            }

            .input-label.clicked {
              color: var(--flick-primary-5, #2ebdb6);
            }
          }
          .input-field {
            padding: 16px 10px 17px 10px;
            width: 100%;
            border-radius: 6px;
            border: 1px solid var(--Flick-Stroke, #eaecf0);
            background: var(--shade-white, #fff);
            outline: none;
            color: var(--flick-secondary-5, #151f32);
            font-size: 12px;
            font-weight: 300;
            line-height: normal;
          }
          .input-field,
          textarea {
            caret-color: var(--flick-primary-5, #2ebdb6);
          }
          .link-form-item {
            width: 100%;
            gap: 6px;
            .link_select_custom {
              width: 25%;
              font-size: 14px;
              font-weight: 450;
              line-height: 24px;
              border: none;
              padding: 12px 5px 12px 5px;
              display: flex;
              align-items: center;
              outline: none;
              align-self: stretch;
              border-radius: 8px;
              border: 1px solid #d0d5dd;
              background: var(--base-white, #fff);
              &:focus {
                outline: none;
              }
            }
            .link_select_custom_value {
              width: 80%;
              border: none;
              padding-top: 8px;
              padding-bottom: 8px;
              padding-left: 12px;
              border: 1px solid #d0d5dd;
              border-radius: 8px;
              padding-top: 8px;
              padding-bottom: 8px;
              &:focus {
                outline: none;
              }
              &:focus {
                outline: none;
              }
            }
          }
        }
      }
    }
    .img-account-name {
      display: flex;
      align-items: center;
      gap: 6px;
      margin-top: 10.5px;
      margin-bottom: 21.5px;
      .account-name-img {
        color: var(--Flick-Primary-5, #2ebdb6);
        font-size: 12px;
        font-weight: 450;
        line-height: normal;
      }
    }
    .connect-account-box {
      width: 24%;
      max-width: 347px;
      border-radius: 10px;
      background: #fff;
      //   padding-bottom: 33px;
      @media (max-width: 1024px) {
        width: 40%;
        max-width: none;
      }

      @media (max-width: "280px") {
        width: 20%;
      }
      @media screen and (max-width: 768px) {
        width: 80%;
      }

      @media screen and (max-width: 425px) {
        width: 90%;
      }

      .close-button {
        display: flex;
        justify-content: flex-end;
        margin-right: 15px;
        margin-top: 15px;
      }
      .flick-logo {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 28px;
        margin-bottom: 24px;
      }
      .super-wallet {
        color: #000;
        text-align: center;
        font-size: 20px;
        line-height: normal;
        letter-spacing: -0.4px;
        font-weight: 200;
        margin-left: 20px;
        margin-right: 20px;
      }
      .flick-policy {
        padding-left: 20px;
        padding-right: 22px;
        margin-bottom: 27px;
        .agree-to {
          display: flex;
          flex-direction: column;
          justify-items: center;
          align-items: center;
          margin-bottom: 10px;
          .select-continue {
            color: var(--Flick-Subtext, #8c8f97);
            text-align: center;
            font-size: 10px;
            font-weight: 450;
            line-height: 16px;
          }
          .flick-end-user {
            color: var(--flick-secondary-5, #151f32);
            font-size: 10px;
            font-weight: 450;
            line-height: 16px;
            text-decoration-line: underline;
            cursor: pointer;
          }
        }
      }

      .connect-effortless {
        display: flex;
        flex-direction: column;
        gap: 28px;
        margin-top: 21px;
        margin-bottom: 32px;
        padding-left: 20px;
        padding-right: 21px;
        @media screen and (max-width: 280px) {
          padding: 0px 10px 0px 10px;
        }

        .border-line {
          border-bottom: 1px solid var(--Flick-Stroke, #eaecf0);
        }

        .connect-title {
          width: 100%;
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          // gap: 16px;
          .connect-link {
            display: flex;
            flex-direction: column;
            // gap: 7px;
          }
          .internet-mobile {
            display: flex;
            align-items: flex-start;
            gap: 16px;
          }
          .connect-your-account-link {
            display: flex;
            flex-direction: column;
            gap: 7px;
            // margin-right: 28.5px;
            @media screen and (max-width: 280px) {
              margin-right: 0px;
            }
          }
          .connect-your-account {
            color: var(--flick-secondary-5, #151f32);
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
          }
          .securely-connect {
            color: var(--Flick-Subtext, #8c8f97);
            font-size: 12px;
            font-weight: 450;
            line-height: 18px;
          }
        }
        .border-top-line {
          display: flex;
          flex-direction: column;
          gap: 4px;
          border-top: 1px solid var(--Flick-Stroke, #eaecf0);
          padding-top: 18px;
          padding-bottom: 10px;
        }
        .text-div {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .light-text {
          color: var(--Subtext, #616367);
          font-size: 12px;
          font-weight: 450;
          line-height: normal;
          letter-spacing: -0.24px;
        }
        .dark-text {
          color: var(--Main-Text, #27272b);
          font-size: 14px;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.28px;
        }
        .transfer {
          display: flex;
          width: 100%;
          padding-top: 11px;
          padding-bottom: 11px;
          flex-direction: column;
          align-items: center;
          gap: 16px;
          border-radius: 8px;
          // background: #faf8f8;
        }
          .transfer-to {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 16px;
            padding-left: 9px;
            padding-right: 9px;
          }
            .transfer-amount {
              color: var(--Flick-Secondary-5, #151f32);
              font-size: 14px;
              font-weight: 500;
              line-height: 20px;
            }
            .authorization {
              display: flex;
              width: 100%;
              justify-content: space-between;
              align-items: center;
              // padding: 11px 12px 10px 11px;
              // border-radius: 8px;
              // border: 1px solid var(--Flick-Stroke, #eaecf0);
              background: #fff;
              .authorize {
                display: flex;
                flex-direction: column;
                gap: 6px;
                .nibss {
                  color: var(--Flick-Secondary-5, #151f32);
                  font-size: 12px;
                  font-weight: 450;
                  line-height: normal;
                }
                .account-copy {
                  display: flex;
                  align-items: flex-start;
                  gap: 5px;
                  color: var(--Subtext-2, #606b81);
                  font-size: 12px;
                  font-weight: 300;
                  line-height: normal;
                }
              }
            }
          
          .transfer-only {
            color: var(--Flick-Secondary-5, #151f32);
            font-size: 12px;
            font-weight: 450;
            line-height: 20px;
          }
      
        .buttons {
          display: flex;
          flex-direction: column;
          gap: 10px;
          margin-top: 26px;
        }
      }
      .associated-account {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .link-associated {
          color: var(--gray-900, #101828);
          text-align: center;
          font-size: 16px;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.32px;
          margin-top: 15px;
          margin-bottom: 4px;
        }
        .we-found {
          color: var(--Flick-Subtext, #8c8f97);
          text-align: center;
          font-size: 12px;
          font-weight: 450;
          line-height: normal;
          letter-spacing: -0.24px;
          width: 75%;
        }
        .rave-we-found {
          color: var(--Flick-Subtext, #8c8f97);
          text-align: center;
          font-size: 16px;
          font-weight: 450;
          line-height: 20px;
          width: 75%;
        }
        .verify-identity {
          color: #000;
          text-align: center;
          font-size: 20px;
          font-weight: 450;
          line-height: normal;
          letter-spacing: -0.4px;
          margin-top: 24px;
          margin-bottom: 6px;
        }
        .document-verify {
          color: #000;
          text-align: center;
          font-size: 16px;
          font-weight: 450;
          line-height: normal;
          letter-spacing: -0.32px;
        }
      }
      //Connect Account
      .connect-accounts-image {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid var(--Flick-Stroke, #eaecf0);
        padding-bottom: 20px;
      }
      .account-name {
        color: var(--Flick-Subtext, #8c8f97);
        font-size: 10px;
        font-weight: 450;
        line-height: 18px;
      }
      .account-type-container {
        display: flex;
        flex-direction: column;
        gap: 14px;
        overflow-y: auto;
        max-height: 270px;
        margin-top: 24px;
        margin-bottom: 24px;

        .account-type {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-radius: 8px;
          border: 1px solid var(--Flick-Stroke, #eaecf0);
          background: #fff;
          padding: 11px 11px 10px 11px;
          @media screen and (max-width: 280px) {
            padding: 11px 5px 10px 5px;
          }

          .account-type-text {
            display: flex;
            flex-direction: column;
            gap: 7px;
            .type-of-account {
              color: var(--flick-secondary-5, #151f32);
              font-size: 12px;
              font-weight: 450;
              line-height: normal;
              @media screen and (max-width: 280px) {
                font-size: 11px;
              }
            }
            .account-number {
              color: var(--Subtext-2, #606b81);
              font-size: 12px;
              font-weight: 300;
              line-height: normal;
            }
            .verification {
              color: var(--flick-secondary-5, #151f32);
              font-size: 14px;
              font-weight: 500;
              line-height: 20px;
            }
            .verify-info {
              color: var(--Flick-Subtext, #8c8f97);
              font-size: 12px;
              font-weight: 450;
              line-height: 18px;
            }
          }
        }
      }
      // Choose Your Bank
      .choose-bank {
        display: flex;
        justify-content: space-between;
        padding: 15px 15px 0px 15px;
        .choose-your-bank {
          color: #000;
          text-align: center;
          font-size: 14px;
          font-weight: 450;
          line-height: normal;
          letter-spacing: -0.28px;
        }
      }
      .mandate {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 4px;
        margin-top: 27.3px;
        margin-bottom: 24.6px;
        .setup-mandate {
          color: var(--Subtext, #616367);
          font-size: 12px;
          font-weight: 450;
          line-height: normal;
          letter-spacing: -0.24px;
        }
        .mandate-amount {
          color: var(--Main-Text, #27272b);
          font-size: 24px;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.48px;
        }
      }

      .selection {
        color: #000;
        text-align: center;
        font-size: 20px;
        font-weight: 300;
        line-height: normal;
        letter-spacing: -0.4px;
      }

      //BVN Consent
      .verify-bvn {
        display: flex;
        flex-direction: column;
        padding: 27px 24px;
        .identity-verify {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 8px;
          margin-bottom: 24px;
          .will-send {
            color: var(--Flick-Subtext, #8c8f97);
            text-align: center;
            font-size: 14px;
            font-weight: 450;
            line-height: 25px;
          }
        }
        .otp-verify {
          display: flex;
          flex-direction: column;
          .phone-email {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-top: 19.5px;
            padding-bottom: 19.5px;
            cursor: pointer;
            .verify-otp {
              display: flex;
              align-items: center;
              gap: 16px;
              .verify-mode {
                color: #151f32;
                font-size: 14px;
                font-weight: 450;
                line-height: 20px;
              }
            }
          }
        }
        .other {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 8px;
          margin-bottom: 8px;
          .or {
            color: var(--Subtext-2, #606b81);
            font-size: 14px;
            font-weight: 450;
            line-height: normal;
            padding: 10px;
            // border-left: 1px solid #EAECF0;
            // border-right: 1px solid #EAECF0;
          }
          .or-line {
            width: 100%;
            border-bottom: 1px solid #eaecf0;
            padding: 0px 50px;
          }
        }

        .mobile-number {
          display: flex;
          flex-direction: column;
          gap: 16px;
          .your-current {
            color: var(--Flick-Subtext, #8c8f97);
            text-align: center;
            font-size: 12px;
            font-weight: 450;
            line-height: 25px;
          }

          .select-code {
            position: relative;
            display: flex;
            width: 100%;
            border-radius: 8px;
            border: 1px solid var(--Flick-Stroke, #eaecf0);
            background: #fff;

            .flag-code {
              display: flex;
              align-items: center;
              width: 82px;
              padding-left: 10px;
              border-radius: 7px 0px 0px 7px;
              background: #f7fdfd;
            }
            .input-select-code {
              outline: none;
              border: none;
              background: #f7fdfd;
              color: var(--flick-secondary-5, #151f32);
              text-align: center;
              font-size: 14px;
              font-weight: 450;
              line-height: 14px;
            }
            .input-phone-number {
              outline: none;
              border: none;
              padding: 16px 10px 17px 10px;
              color: var(--Flick-Subtext, #8c8f97);
              font-size: 12px;
              font-weight: 300;
              line-height: normal;
            }
            .input-phone-number,
            textarea {
              caret-color: var(--flick-primary-5, #2ebdb6);
            }
          }
        }
        .qr {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .continue- {
          display: flex;
          align-items: flex-start;
          gap: 6px;
          padding: 10px;
          border-radius: 8px;
          border: 1px solid var(--Flick-Stroke, #eaecf0);
          background: #fafafa;
          .save-link {
            color: var(--Gray-800, #1d2939);
            font-size: 12px;
            font-weight: 450;
            line-height: 18px;
          }
        }
      }

      //Enter Credentials

      .input_container {
        position: relative;
        padding-top: 15px;
        width: 100%;
        .input-group {
          border-radius: 8px;
          border: 1px solid var(--Flick-Stroke, #eaecf0);
          background: #fff;
          display: flex;
          align-items: center;
          background: #fff;
          padding: 16px 10px 17px 10px;
          .input-name {
            color: var(--flick-secondary-5, #151f32);
            font-size: 12px;
            font-weight: 300;
            line-height: normal;
            border: none;
            width: 90%;
            &:focus {
              outline: none;
            }
          }
          .input-name,
          textarea {
            caret-color: var(--flick-primary-5, #2ebdb6);
          }
        }
        .input-label {
          position: absolute;
          top: 0;
          left: 6px;
          color: var(--Flick-Subtext, #8c8f97);
          font-size: 12px;
          font-weight: 450;
          line-height: 20px;
          background: #fff;
          padding: 4px;
        }

        .input-group.clicked {
          border: 1px solid var(--flick-primary-5, #2ebdb6);
        }

        .input-label.clicked {
          color: var(--flick-primary-5, #2ebdb6);
        }
      }
      .input-field {
        padding: 16px 10px 17px 10px;
        width: 100%;
        border-radius: 6px;
        border: 1px solid var(--Flick-Stroke, #eaecf0);
        background: var(--shade-white, #fff);
        outline: none;
        color: var(--flick-secondary-5, #151f32);
        font-size: 12px;
        font-weight: 300;
        line-height: normal;
      }
      .input-field,
      textarea {
        caret-color: var(--flick-primary-5, #2ebdb6);
      }

      //Enter Credentials
      .enter-credentials-box {
        padding: 0px 21px 21px 21px;
      }
      .img-logo {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .enter-credentials {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 16px;
        .enter-your-credentials {
          color: var(--flick-secondary-5, #151f32);
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
        }
        .credentials {
          color: var(--Flick-Subtext, #8c8f97);
          font-size: 12px;
          font-weight: 450;
          line-height: 18px;
          @media screen and (max-width: 280px) {
            font-size: 10px;
          }
        }
      }
      .secured-flick {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 24px;
      }
      .enter-token {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        border-radius: 8px;
        background: rgba(205, 217, 216, 0.15);
        padding: 11px 9px;
        gap: 16px;
        margin-bottom: 19px;
        margin-top: 27px;
        .token-nibss {
          color: var(--Flick-Secondary-5, #151f32);
          text-align: center;
          font-size: 14px;
          font-weight: 450;
          line-height: 20px;
        }
        .rave-verify-otp {
          display: flex;
          flex-direction: column;

          .rave-verify-field {
            display: flex;
            align-items: center;
            justify-content: center;
            .rave-otp {
              padding-top: 5px;
              padding-bottom: 5px;
              outline: none;
              background: #fff;
              border-radius: 8px;
              border: 1px solid var(--flick-primary-05, #eaf8f8);
              width: 100%;
              // height: 15px;
              color: var(--flick-primary-5, #2ebdb6);
              text-align: center;
              font-size: 50px;
              font-weight: 450;
              line-height: normal;
            }
          }
        }
        .otp-to {
          color: var(--Flick-Secondary-5, #151f32);
          font-size: 13px;
          font-weight: 300;
          line-height: 20px;
          text-align: center;
        }
      }
      .enter-transaction {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        margin-top: 24px;
        margin-bottom: 32px;
      }
      .enter-otp {
        color: #000;
        text-align: center;
        font-size: 16px;
        font-weight: 450;
        line-height: normal;
        letter-spacing: -0.32px;
      }
      .input-token {
        color: var(--Flick-Secondary-5, #151f32);
        text-align: center;
        font-size: 14px;
        font-weight: 300;
        line-height: 20px;
      }
      .five-digit {
        width: 80%;
        color: var(--Flick-Subtext, #8c8f97);
        text-align: center;
        font-size: 14px;
        font-weight: 450;
        line-height: 18px;
      }
      .input-verify-otp {
        display: flex;
        flex-direction: column;
        padding-bottom: 58px;
        gap: 16px;

        .input-verify-field {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 48px;
          // background: #f7fcfc;
          // border-radius: 8px;
          // width: 100%;
          // padding-top: 27px;
          // padding-bottom: 27px;
          .-otp {
            border-radius: 8px;
            border: 1px solid var(--flick-primary-05, #eaf8f8);
            background: #f7fcfc;
            outline: none;
            width: 100%;
            // height: 15px;
            color: var(--flick-primary-5, #2ebdb6);
            text-align: center;
            font-size: 50px;
            letter-spacing: 20px;
            padding-top: 5px;
            padding-bottom: 5px;
          }
        }
        .code {
          color: var(--Gray-600, #475467);
          text-align: center;
          font-size: 14px;
          font-weight: 450;
          line-height: 14px;
          .click {
            color: var(--Flick-Primary-5, #2ebdb6);
            font-size: 14px;
            font-weight: 450;
            line-height: 14px;
            text-decoration-line: underline;
          }
        }
      }
      .check-policy {
        display: flex;
        align-items: flex-start;
        gap: 3px;
        margin-bottom: 16px;

        .proceeding {
          color: var(--Flick-Subtext, #8c8f97);
          font-size: 10px;
          font-weight: 500;
          line-height: 16px;
          .end-user {
            color: var(--Flick-Primary-5, #2ebdb6);
            text-decoration-line: underline;
          }
        }
      }

      //Connect Account
      .connect-accounts-image {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid var(--Flick-Stroke, #eaecf0);
        padding-bottom: 20px;
      }
      .account-name {
        color: var(--Flick-Subtext, #8c8f97);
        font-size: 10px;
        font-weight: 450;
        line-height: 18px;
      }
      .account-type-container {
        display: flex;
        flex-direction: column;
        gap: 14px;
        overflow-y: auto;
        max-height: 270px;
        margin-top: 24px;
        margin-bottom: 24px;

        .account-type {
          display: flex;
          justify-content: space-between;
          border-radius: 8px;
          border: 1px solid var(--Flick-Stroke, #eaecf0);
          background: #fff;
          padding: 11px 11px 10px 11px;
          @media screen and (max-width: 280px) {
            padding: 11px 5px 10px 5px;
          }

          .account-type-text {
            display: flex;
            flex-direction: column;
            gap: 7px;
            .type-of-account {
              color: var(--flick-secondary-5, #151f32);
              font-size: 12px;
              font-weight: 450;
              line-height: normal;
              @media screen and (max-width: 280px) {
                font-size: 11px;
              }
            }
            .account-number {
              color: var(--Subtext-2, #606b81);
              font-size: 12px;
              font-weight: 300;
              line-height: normal;
            }
          }
        }
      }

      .link-text {
        color: var(--Flick-Primary-5, #2ebdb6);
        // font-family: Inter;
        font-size: 10px;
        font-weight: 400;
        line-height: normal;
      }
      //Account Connected
      .success-flick {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 80px;
      }
      .connected {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-top: 24px;
        margin-bottom: 107px;
        // padding: 24px 63px 80px 63px;
        gap: 4px;
        @media screen and (max-width: 280px) {
          padding: 24px 20px 80px 20px;
        }
      }
      .consent {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-top: 24px;
        margin-bottom: 24px;
        gap: 4px;
        @media screen and (max-width: 280px) {
          padding: 24px 20px 80px 20px;
        }
      }
      .account-success {
        color: var(--Flick-Secondary-5, #151f32);
        text-align: center;
        font-size: 14px;
        font-weight: 450;
        line-height: 20px;
      }
      .account-connected {
        color: var(--flick-secondary-5, #151f32);
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        text-align: center;
        padding-left: 43px;
        padding-right: 43px;
      }
      .your-account {
        color: var(--Flick-Subtext, #8c8f97);
        text-align: center;
        font-size: 12px;
        font-weight: 450;
        line-height: 18px;
        width: 70%;
      }
      .consent-btn {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 37px;
        padding-left: 22px;
        padding-right: 22px;
      }
      .connected-btn {
        display: flex;
        flex-direction: column;
        padding: 67px 22px 22px 22px;
        gap: 11px;
      }

      //SelectBanks

      .select-banks {
        display: flex;
        flex-direction: column;
        padding: 27px 21px 21.5px 21px;
        .search-banks {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 11px 14px;
          border-radius: 8px;
          border: 1px solid var(--Flick-Stroke, #eaecf0);
          box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03),
            0px 12px 16px -4px rgba(16, 24, 40, 0.08);
          margin-bottom: 22px;
          .search-field {
            width: 100%;
            border: none;
            outline: none;
            color: var(--flick-secondary-5, #151f32);
            font-size: 12px;
            font-weight: 450;
            line-height: normal;
            line-height: 20px;
            letter-spacing: 0.24px;
          }
          .search-field,
          textarea {
            caret-color: var(--flick-primary-5, #2ebdb6);
          }
        }
        .banking {
          display: flex;
          margin-bottom: 16px;
          gap: 5px;
          .banking-option {
            color: var(--flick-secondary-5, #151f32);
            font-size: 14px;
            font-weight: 450;
            line-height: 20px;
            text-align: center;
            padding: 10px 49px 10px 49px;
          }
          .banking-active {
            border-bottom: 1px solid #259792;
          }
        }

        .bank-list {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          max-height: 220px;
          overflow-y: auto;
          gap: 8px;
          margin-bottom: 21.5px;
          .select-bank {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border-radius: 8px;
            background: #f8f8f8;
            flex-basis: calc(33.33% - 8px);
            max-width: calc(33.33% - 8px);
            height: 96px;
            padding: 20px 23px;
            @media screen and (max-width: 280px) {
              padding: 0px 10px 0px 10px;
            }
            .selected {
              background: #2ebdb6;
              color: #ffffff;
            }

            .bank-name {
              color: var(--flick-secondary-5, #151f32);
              font-size: 14px;
              font-weight: 450;
              line-height: 20px;
              margin-top: 12px;
              @media screen and (max-width: 280px) {
                font-size: 12px;
              }
            }
            .selected-text {
              color: #ffffff;
            }
          }
          .selected {
            background-color: #2ebdb6;
          }
        }
      }
    }
  }
  .custom-toast-container {
    width: 29%;
    @media screen and (max-width: 280px) {
      width: 280px;
    }
  }
}

//OTP

.enter-otp-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px 24px 24px 24px;
  gap: 14px;
  width: 100%;
  max-width: 310px;
  position: fixed;
  z-index: 1;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
  background: var(--base-white, #fff);
  box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03),
    0px 20px 24px -4px rgba(16, 24, 40, 0.08);
  .modal-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    .enter-otp {
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--flick-secondary-5, #151f32);
      font-size: 14px;
      font-weight: 450;
      line-height: 20px;
    }
    .input-otp {
      display: flex;
      flex-direction: column;
      padding-top: 20px;
      padding-bottom: 20px;
      border-radius: 6px;

      .input-otp-field {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        width: 100%;
        .otp {
          display: flex;
          align-items: center;
          justify-content: space-around;
          outline: none;
          width: 70%;
          height: 45px;
          border-radius: 8px;
          border: 2px solid var(--flick-primary-5, #2ebdb6);
          background: var(--base-white, #fff);
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          color: var(--flick-primary-5, #2ebdb6);
          text-align: center;
          font-size: 20px;
          font-weight: 250;
          line-height: 50px;
          letter-spacing: 10px;
        }
      }
    }
    .otp-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;
      padding: 10px 12px;
      border-radius: 6px;
      height: auto;
      position: relative;
      z-index: 1;
      background-color: #151f32;
      border-radius: 6px;
      background: var(--flick-primary-6, #259792);
      color: #f7f8fa;
      overflow: hidden;
      border: none;
      outline: none;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
    }
  }
}

.container_overlay {
  width: 100%;
  position: relative;
  background: red;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading_overlay {
  width: 100%;
  height: 100%;
  position: absolute;

  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(46, 189, 182);
}
.loader_icon {
  margin: auto;
}

// Not found

.error_container {
  width: 100%;
  height: 100vh;
  background: #f6f7f9;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding-bottom: 6rem;
  margin: 0 auto;
}

.error_content {
  border-radius: 10px;
  background: #fff;
  width: 100%;
  max-width: 380px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  padding-bottom: 1.6rem;
  margin-top: 25vh;
  .invalid_content {
    margin-top: 5vh;
  }
  background: #fff;
  width: 100%;
  max-width: 380px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  padding-bottom: 4rem;
  margin-top: 25vh;
  .invalid_content {
    margin-top: 5vh;
  }

  .title {
    color: var(--gray-900, #101828);
    text-align: center;
    font-family: Circular Std;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.32px;
    margin-top: 3rem;
  }
  .text {
    color: #fff;
    font-family: Circular Std;
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 152.523%; /* 61.009px */
    background: #ed1c24;
    width: fit-content;
    padding: 1rem;
    margin-top: 3rem;
  }
}

.securedBtn {
  padding: 12px 24px;
  margin-top: 32px;
  border-radius: 4px;
  border: 1px solid #e6e6e6;
  background: #fff;
  display: flex;
  gap: 8px;
  cursor: pointer;
}
.secured_content {
  display: flex;
  gap: 4px;
  align-items: center;
}
.secured_p {
  color: #000;
  font-family: Circular Std;
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  letter-spacing: -0.35px;
}

.error_invalid {
  font-family: Circular Std;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.35px;
  color: #ababab;
  margin: 0 0 50px;
}

.error_invalid_header {
  color: #2f3d4d;
  line-height: 1.4;
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 18px;
}
